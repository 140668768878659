@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');

.contact-us-page-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex: 1.2;
    margin-top: 100px;
}

.contact-us-info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.contact-us-form-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: baseline;
    padding: 20px;
}

.contact-type-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: max-content;
}

.contact-us-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
}

.contact-content {
	float: left;
	width: 100%;
    position: relative;
    font-size: 16px;	
    content: "\e9d1";
    text-decoration: none;
    display: flex;
    font-weight: 400;
    line-height: 1.7;
    color: #828282;
}

.crestview-title {
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    color: #000000;
    font-size: 2vw;
    margin: 15px;
    font-weight: bold;
}

.contact-us-title {
    display: flex;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    color: #000000;
    font-size: 2vw;
    margin-left: 15px;
    margin-top: 15px;
    font-weight: bold;
}

.contact-type-section {
    display: flex;
    flex-direction: column;
    font-family: 'Roboto', sans-serif;
}

.google-map-small {
    display: none;
}

.google-map-medium {
    display: flex;
}

.google-map-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
}

@media screen and (max-width: 576px) {
    .contact-us-page-container {
        flex-direction: column;
        margin-top: 20px;
    }

    .contact-us-form-container {
        padding: unset;
        align-items: center;
    }

    .contact-us-title {
        display: none;
    }

    .contact-us-info-container {
        width: 100%;
        padding: unset;
    }

    .contact-us-info {
        padding: 30px 0px;
    }

    .crestview-title {
        font-size: 6vw;
    }

    .contact-content {
        font-size: 20px;
    }

    .google-map-container {
        width: inherit;
    }

    .google-map-small {
        display: flex;
        width: 100%;
    }
    
    .google-map-medium {
        display: none;
    }
}