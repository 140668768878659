.job-panel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
}

.secondary-heading {
    color: #43273f;
}

.column {
    width: 50%;
}

.detail-column {
    width: 50%;
    padding: 15px;
    display: flex;
    justify-content: flex-start;
}

.details-section {
    display: flex;
    align-items: center;
}

.apply-now-button {
    width: 30%;
    display: flex;
    flex-direction: row;
}

.summary-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

@media screen and (max-width: 576px) {
    .summary-section {
        display: flex;
        flex-direction: column;
    }

    .column {
        width: unset;
    }

    .details-section {
        display: flex;
        flex-direction: column;
    }

    .detail-column {
        width: unset;
    }

    .apply-now-button {
        width: 100%;
    }
}