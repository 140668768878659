.picture-and-text-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px;
    flex-direction: row;
}

.text {
    padding: 20px;
    font-size: 1.5vw;
    text-align: justify;
    flex: 1;
    -ms-flex: 1;
    -webkit-box-flex: 1;
}

.picture {
    padding: 20px;
    flex: 1;
    -ms-flex: 1;
    -webkit-box-flex: 1;
}

.row {
    flex-direction: row;
}

.row-reverse {
    flex-direction: row-reverse;
}

.column {
    flex-direction: column;
}

.column-reverse {
    flex-direction: column-reverse;
}

@media screen and (max-width: 576px) {
    .picture-and-text-container {
        flex-direction: column;
        padding: unset;
    }

    .text {
        font-size: 5vw;
        padding: 0px 30px;
    }
}