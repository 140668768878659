.full-width-tabs-container {
    display: flex;
    flex-direction: column;
    margin: 100px;
}

@media screen and (max-width: 576px) {
    .full-width-tabs-container {
        margin: 30px;
    }
}