.careers-form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1.2;
    margin-bottom: 100px;
    padding: 0px 50px;
}

.careers-title {
    display: flex;
    -ms-flex-pack: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: start;
    color: #000000;
    font-size: 3vw;
    margin-left: 15px;
    margin-bottom: 15px;
    font-weight: bold;
}

@media screen and (max-width: 576px) {
    .contact-us-form-container {
        padding: unset;
        align-items: center;
    }

    .contact-us-title {
        display: none;
    }

    .careers-title {
        font-size: 7vw;
        margin-left: 0px;
        justify-content: center;
    }
}