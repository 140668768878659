.floor-plan-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    width: 30%;
    padding: 15px;
    border-radius: 20px;
    margin: 15px;
}

.floor-plan-name {
    font-size: 2vw;
    text-align: center;
}

.floor-plan-property {
    font-size: 1vw;
    text-align: center;
    padding: 5px;
}

.square-footage {
    font-size: 1.5vw;
    text-align: center;
}

.floor-plan-picture {
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    justify-content: center;
    height: auto;
    padding: 10px;
    -webkit-align-items: center;
}

@media screen and (max-width: 576px) {
    .floor-plan-container {
        width: unset;
        margin: 20px;
    }

    .floor-plan-name {
        font-size: 6vw;
    }
    
    .floor-plan-picture {
        width: 80%;
    }

    .square-footage {
        font-size: 3vw;
    }

    .floor-plan-property {
        font-size: 4vw;
    }

}