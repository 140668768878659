.form-section-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0px 20px;
    font-family: "Raleway", Arial, sans-serif;
}

.section-name {
    max-width: fit-content;
    margin-bottom: 5px;
    font-size: 16px;
    line-height: 1.7;
    color: #828282;
}

.section-input {
    box-shadow: none;
    background: transparent;
    border: 2px solid rgba(0, 0, 0, 0.1);
    height: 30px;
    font-size: 18px;
    font-weight: 300;
    border-radius: 5px;
    padding: 10px 20px;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    line-height: 1.42857;
    color: #555555;
}

.section-input:focus {
    outline: none;
    box-shadow: none;
    border-color: #292f51
}

.error {
    border-color: #FF0000;
}

.section-error-message {
    color: #FF0000;
    padding: 5px;
}

.section-error-message-hidden {
    visibility: hidden;
    padding: 5px;
}

::placeholder {
    font-family: "Raleway", Arial, sans-serif;
}

@media screen and (max-width: 576px) {

    .form-section-container {
        padding: 10px;
    }
}



/* The Following is for form upload */
.file-upload-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.file-upload-section {
    border-radius: 25px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    font-size: 18px;
    font-weight: 300;
    border-radius: 5px;
    padding: 30px;
    line-height: 1.42857;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70vw;
}

.file-upload-title {
    font-size: 18px;
    font-weight: 300;
    width: 100%;
    text-align: start;
    padding: 10px;
}

.file-upload-text {
    padding: 0px;
}

input[type="file"] {
    opacity: 0;
    position: absolute;
    pointer-events: none;
    width: 1px;
    height: 1px;
}

input[type="file"] + label {
    color: #0000EE;
    text-decoration: underline;
    cursor: pointer;
}

@media screen and (max-width: 576px) {
    .file-upload-title {
        width: unset;
    }

    .file-upload-container {
        align-items: start;
    }

    .file-upload-section {
        width: unset;
    }
}