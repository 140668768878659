.divider-container {
    margin: 50px 20px;
    padding: 40px;
}

.divider {
    border-color: #ab926e;
}

@media screen and (max-width: 576px) {
    .divider-container {
        margin: 10px;
        padding: 25px;
    }
}