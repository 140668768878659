.item-row-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 20px;
}

@media screen and (max-width: 576px) {
    .item-row-container {
        width: 100%;
        align-items: center;
        flex-direction: column;
        margin: unset;
        margin: unset;
    }
}