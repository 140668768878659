.offer-container {
    display: table-cell;
    flex-direction: row;
    padding: 10px;
    width: 30%;
}

.offer-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.offer-icon {
    justify-content: flex-start;
    padding: 0px 10px 10px 10px;
    float: left;
}

.offer-header {
    display: flex;
    text-transform: uppercase;
    font-size: 18px;
    color: #5d5d5d;
    margin-bottom: 10px;
    font-weight: bold;
    line-height: 1.1;
}

.offer-text {
    font-size: 16px;
    line-height: 1.7; 
    color: #666666;
    font-weight: 400;
}

@media screen and (max-width: 576px) {
    .offer-container {
        width: unset;
    }
}