.picture-and-bullets-container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.bullet-list-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 2vw;
    padding: 10px;
    flex: 1;
    -ms-flex: 1;
    -webkit-box-flex: 1;
}

.picture {
    padding: 20px;
    flex: 1;
    -ms-flex: 1;
    -webkit-box-flex: 1;
}

@media screen and (max-width: 576px) {
    .picture-and-bullets-container {
        flex-direction: column;
        padding: unset;
    }

    .bullet-list-container {
        font-size: 5vw;
        padding: unset;
    }
}