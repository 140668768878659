.service-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    width: 30%;
}

.service-item-name {
    font-size: 1.5vw;
    text-align: center;
    margin: 10px;
}

.service-item-text {
    font-size: 1vw;
    text-align: center;
    margin: 25px;
}

.service-award-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 576px) {
    .service-item-name {
        font-size: 8vw;
        text-align: center;
        margin: 10px;
    }

    .service-container {
        width: 100%;
    }

    .service-item-text {
        font-size: 4vw;
        margin: 15px;
    }
}