.parallax-banner {
    height: 800px;
    width: 100%;
    object-fit: cover;
}

.banner-container {
    display: flex;
    width: 100%;
    height: auto;
    position: relative;
    background: rgba(0, 0, 0, 0.4);
}

.text-container {
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    background: rgba(0, 0, 0, 0.4);
}

.title-text {
    color: #ffffff;
    font-size: 5vw;
    text-align: center;
    text-transform: uppercase;
    padding: 100px;
}

.sub-text {
    color: #ffffff;
    font-size: 3vw;
    text-align: center;
    padding: 20px;
}

.sub-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media screen and (max-width: 576px) {
    .parallax-banner {
        height: 200px;
        width: 100%;
        object-fit: cover;
    }

    .title-text {
        font-size: 7vw;
        padding: 20px;
    }

    .sub-text {
        font-size: 5vw;
        padding: 5px;
    }
}

@media screen and (max-width: 786px) {
    .parallax-banner {
        height: 500px;
        width: 100%;
        object-fit: cover;
    }
}