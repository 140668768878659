.gallery-section-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 0px 30px 0px 30px;
}

.gallery-image {
    width: 100%;
    height: 100%;
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.gallery-image-and-description-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.gallery-description {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}