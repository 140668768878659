.page-buttons-container {
	display: flex;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1200;
    opacity: 1;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
}

.page-buttons-container a {
    width: 50px;
    height: 40px;
    display: table;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    text-align: center;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    border-radius: 4px;
}

.page-buttons-container a i {
    height: 40px;
    display: table-cell;
    vertical-align: middle;
}

.page-buttons-container a:hover, .page-buttons-container a:active, .page-buttons-container a:focus {
    text-decoration: none;
    outline: none;
}