@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Raleway&display=swap);
.footer-container {
    background-color: #292f51;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 30px 50px 30px;
    box-shadow: 0px 0px 20px 0px #888888;
    font-family: 'Roboto', sans-serif;
}

.footer-text {
    color: #ab926e;
    font-size: 25px;
    padding: 5px;
    text-decoration: none;
}

.footer-text-barrier {
    color: #ab926e;
    font-size: 25px;
    padding: 5px;
}

.connect-with-us-text {
    color: #ab926e;
    font-size: 25px;
    padding: 20px;
}

.social-media-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.facebook-icon {
    color: #ab926e;
}

.facebook-icon:hover {
    color: #4267B2;
}

.instagram-icon {
    color: #ab926e;
}

.instagram-icon:hover {
    color: #DD2A7B;
}

.linkedin-icon {
    color: #ab926e;
}

.linkedin-icon:hover {
    color: #0073b1;
}

.eqo-handicap-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.eqo-handicap-picture {
    width: 5%;
}

.footer-alt-info-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.footer-link {
    text-decoration: none;
    color: #ab926e;
    padding: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-logo-container {
    width: 10%;
    height: auto;
    padding: 15px;
}


.footer-link-section-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: start;
    width: 100%;
    margin: 0px 20px 20px 20px;
}

.footer-link-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    color: #ab926e !important;
    font-size: 20px;
    padding: 20px;
    text-decoration: none;
}

.footer-divider {
    width: inherit;
    display: flex;
    height: 3px;
    background-color: #292f51;
    border-radius: 5px;
}

.footer-section-link {
    margin: 10px;
    cursor: pointer;
}

.footer-awards-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.best-meals-and-dining-award-container {
    width: 9%;
}

.best-in-senior-living-award-container {
    width: 15%;
    cursor: pointer;
}

@media screen and (max-width: 576px) {
    .footer-container {
        padding: 0px 20px 60px 20px;
    }

    .footer-divider {
        margin: 5px;
        width: 100%;
    }

    .footer-title {
        font-size: 26px;
        text-align: center;
    }

    .footer-text {
        font-size: 20px;
        margin-bottom: 0px;
    }

    .footer-text-barrier {
        display: none;
    }

    .footer-link-section {
        margin: 0px;
    }

    .footer-logo-container { 
        width: 30%;
        padding: 20px;
    }

    .footer-alt-info-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
        text-decoration: none;
    }

    .eqo-handicap-picture {
        width: 15%;
    }

    .best-meals-and-dining-award-container {
        width: 24%;
    }
    
    .best-in-senior-living-award-container {
        width: 40%;
    }
}
.img-container {
    width: 100%;
    height: auto;
    position: relative;
    border-radius: 10px;
}

.webp-picture-container {
    position: relative;
    display: flex;
    justify-content: center;
	width: 100%;
	align-items: center;
}

.best-meals-and-dining-container {
    position: relative;
    display: flex;
    justify-content: center;
	align-items: center;
	padding: 30px;
}

.best-meals-and-dining {
	width: auto;
}

.expanded-webp-picture-container {
	position: relative;
    display: flex;
	justify-content: center;
	width: 45%;
}

.zoom-in-icon {
	font-size: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
}



.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .3s ease;
    background-color: rgb(165, 165, 165, .5);
}

  /* The icon inside the overlay is positioned in the middle vertically and horizontally */
.icon {
    color: white;
    font-size: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.modal-container {
	position: absolute;
	top: 50%;
	left: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 2000;
	background: rgba(256, 256, 256, .8)
}

.close-button-icon {
	position: absolute;
	right: 20px;
	top: 20px;
	cursor: pointer;
	color: #555555;
}

.close-button-icon:hover {
	color: #43273f;
}

.print-icon {
	position: absolute;
	right: 80px;
	top: 20px;
	cursor: pointer;
	color: #555555;
}

.print-icon:hover {
	color: #43273f;
}

.share-icon {
	position: absolute;
	right: 140px;
	top: 20px;
	cursor: pointer;
	color: #555555;
}

.share-icon:hover {
	color: #43273f;
}
  
/* When you move the mouse over the icon, change color */
.fa-user:hover {
	color: #43273f;
}

@media screen and (max-width: 576px) {
  .img-container {
		height: intrinsic;
	}
	
	.webp-picture-container {
		height: inherit;
	}

	.expanded-webp-picture-container {
		position: relative;
		display: flex;
		justify-content: center;
		width: 75%;
	}
}


.coming-soon-banner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: inherit;
    margin: 40px 0px 70px 0px;
    background-color: #ab926e;
    box-shadow: 0px 0px 5px 0px #888888;
}

.coming-soon-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    margin: 20px;
}

.coming-soon-header {
    margin: 10px;
    font-size: 50px;
    font-weight: bold;
    text-align: center;
    color: #292f51;
}

.coming-soon-subheader {
    margin: 20px 100px;
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    color: #292f51;
}

@media screen and (max-width: 576px) {
    .coming-soon-header {
        font-size: 35px;
    }

    .coming-soon-subheader {
        font-size: 20px;
    }

    .coming-soon-button-container {
        width: unset;
    }
}
.service-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    width: 30%;
}

.service-item-name {
    font-size: 1.5vw;
    text-align: center;
    margin: 10px;
}

.service-item-text {
    font-size: 1vw;
    text-align: center;
    margin: 25px;
}

.service-award-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 576px) {
    .service-item-name {
        font-size: 8vw;
        text-align: center;
        margin: 10px;
    }

    .service-container {
        width: 100%;
    }

    .service-item-text {
        font-size: 4vw;
        margin: 15px;
    }
}
.item-row-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 20px;
}

@media screen and (max-width: 576px) {
    .item-row-container {
        width: 100%;
        align-items: center;
        flex-direction: column;
        margin: unset;
        margin: unset;
    }
}
.divider-container {
    margin: 50px 20px;
    padding: 40px;
}

.divider {
    border-color: #ab926e;
}

@media screen and (max-width: 576px) {
    .divider-container {
        margin: 10px;
        padding: 25px;
    }
}
.outlined-button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-content: center;
    width: -webkit-fill-available;
}

.btn {
    margin-right: 4px;
    margin-bottom: 4px;
    font-family: "Raleway", Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    border-radius: 4px;
    transition: 0.5s;
    padding: 8px 30px;
    text-decoration: none;
}

.btn.btn-md {
    padding: 8px 20px !important;
}

.btn.btn-lg {
    padding: 18px 36px !important;
}

.btn:hover, .btn:active, .btn:focus {
    box-shadow: none !important;
    outline: none !important;
}

.btn-primary {
    background: #292f51;
    color: #ffffff;
    border: 2px solid #292f51;
}

.btn-primary:disabled:hover {
    background: #cccccc;
}

.btn-primary:disabled {
    background: #cccccc;
    border: none;
}

.btn-primary:enabled:hover, .btn-primary:focus, .btn-primary:active {
    background: #292f51 !important;
    border-color: #43273f !important;
    cursor: pointer;
}

.btn-primary.btn-outline {
    display: flex;
    justify-content: center;
    width: auto;
    align-items: center;
    text-align: center;
    background: rgba(256,256,256,0.8);
    color: #292f51;
    border: 2px solid #43273f;
    width: 100%;
}

.btn-primary.btn-outline:hover, .btn-primary.btn-outline:focus, .btn-primary.btn-outline:active {
    background: #292f51;
    color: #fff;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .btn-primary.btn-outline {
        color: white;
        background: rgba(121, 92, 50, 0.9);
    }
}

@media screen and (max-width: 576px) {
    .outlined-button {
        margin: 0px 20px;
    }
}
.row-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.offer-container {
    display: table-cell;
    flex-direction: row;
    padding: 10px;
    width: 30%;
}

.offer-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.offer-icon {
    justify-content: flex-start;
    padding: 0px 10px 10px 10px;
    float: left;
}

.offer-header {
    display: flex;
    text-transform: uppercase;
    font-size: 18px;
    color: #5d5d5d;
    margin-bottom: 10px;
    font-weight: bold;
    line-height: 1.1;
}

.offer-text {
    font-size: 16px;
    line-height: 1.7; 
    color: #666666;
    font-weight: 400;
}

@media screen and (max-width: 576px) {
    .offer-container {
        width: unset;
    }
}
.gallery-section-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 0px 30px 0px 30px;
}

.gallery-image {
    width: 100%;
    height: 100%;
    margin: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.gallery-image-and-description-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.gallery-description {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
}
.floor-plan-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: auto;
    width: 30%;
    padding: 15px;
    border-radius: 20px;
    margin: 15px;
}

.floor-plan-name {
    font-size: 2vw;
    text-align: center;
}

.floor-plan-property {
    font-size: 1vw;
    text-align: center;
    padding: 5px;
}

.square-footage {
    font-size: 1.5vw;
    text-align: center;
}

.floor-plan-picture {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    padding: 10px;
    -webkit-align-items: center;
}

@media screen and (max-width: 576px) {
    .floor-plan-container {
        width: unset;
        margin: 20px;
    }

    .floor-plan-name {
        font-size: 6vw;
    }
    
    .floor-plan-picture {
        width: 80%;
    }

    .square-footage {
        font-size: 3vw;
    }

    .floor-plan-property {
        font-size: 4vw;
    }

}
.video-header-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.video-player-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;
}

.react-player {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}


.section-title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #000000;
    font-size: 3vw;
    margin: 20px;
    margin-top: 40px;
    font-weight: bold;
}

@media screen and (max-width: 576px) {
    .section-title-container {
        margin: 35px 10px 0px 10px;
        font-size: 9vw;
        width: unset;
    }
}
.picture-and-text-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px;
    flex-direction: row;
}

.text {
    padding: 20px;
    font-size: 1.5vw;
    text-align: justify;
    flex: 1 1;
    -ms-flex: 1;
    -webkit-box-flex: 1;
}

.picture {
    padding: 20px;
    flex: 1 1;
    -ms-flex: 1;
    -webkit-box-flex: 1;
}

.row {
    flex-direction: row;
}

.row-reverse {
    flex-direction: row-reverse;
}

.column {
    flex-direction: column;
}

.column-reverse {
    flex-direction: column-reverse;
}

@media screen and (max-width: 576px) {
    .picture-and-text-container {
        flex-direction: column;
        padding: unset;
    }

    .text {
        font-size: 5vw;
        padding: 0px 30px;
    }
}
.parallax-banner {
    height: 800px;
    width: 100%;
    object-fit: cover;
}

.banner-container {
    display: flex;
    width: 100%;
    height: auto;
    position: relative;
    background: rgba(0, 0, 0, 0.4);
}

.text-container {
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    flex-direction: column;
    align-items: center;
    z-index: 1;
    background: rgba(0, 0, 0, 0.4);
}

.title-text {
    color: #ffffff;
    font-size: 5vw;
    text-align: center;
    text-transform: uppercase;
    padding: 100px;
}

.sub-text {
    color: #ffffff;
    font-size: 3vw;
    text-align: center;
    padding: 20px;
}

.sub-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media screen and (max-width: 576px) {
    .parallax-banner {
        height: 200px;
        width: 100%;
        object-fit: cover;
    }

    .title-text {
        font-size: 7vw;
        padding: 20px;
    }

    .sub-text {
        font-size: 5vw;
        padding: 5px;
    }
}

@media screen and (max-width: 786px) {
    .parallax-banner {
        height: 500px;
        width: 100%;
        object-fit: cover;
    }
}
.iframe {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0px;
    zoom: 3;
}

.iframe-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 576px) {

    .iframe {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px 0px;
        zoom: 2;
    }
    
    .iframe-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

}
.careers-form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex: 1.2 1;
    margin-bottom: 100px;
    padding: 0px 50px;
}

.careers-title {
    display: flex;
    -ms-flex-pack: center;
    align-items: center;
    text-align: start;
    color: #000000;
    font-size: 3vw;
    margin-left: 15px;
    margin-bottom: 15px;
    font-weight: bold;
}

@media screen and (max-width: 576px) {
    .contact-us-form-container {
        padding: unset;
        align-items: center;
    }

    .contact-us-title {
        display: none;
    }

    .careers-title {
        font-size: 7vw;
        margin-left: 0px;
        justify-content: center;
    }
}
.careers-page-form-container {
    width: -webkit-fill-available;
    padding: 20px;
}

.form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: -webkit-fill-available;
    padding: 20px;
}

.form-row-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 5px;
    width: 100%;
}

.form-submit-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: inherit;
}

.form-file-upload-container {
    width: -webkit-fill-available;
    padding: 0px 25px 25px 25px;
    display: flex;
    justify-content: center;
}

.no-job-postings {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 30px;
    margin: unset;
}

@media screen and (max-width: 576px) {

    .form-file-upload-container {
        padding: 0px 0px 30px 0px;
    }

    .careers-page-form-container {
        padding: unset;
    }

    .form-container {
        padding: unset;
        width: unset;
    }

    .form-row-container {
        flex-direction: column;
        padding: unset;
    }

    .form-submit-row {
        flex-direction: column-reverse;
    }
}
.form-section-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0px 20px;
    font-family: "Raleway", Arial, sans-serif;
}

.section-name {
    max-width: -webkit-fit-content;
    max-width: -moz-fit-content;
    max-width: fit-content;
    margin-bottom: 5px;
    font-size: 16px;
    line-height: 1.7;
    color: #828282;
}

.section-input {
    box-shadow: none;
    background: transparent;
    border: 2px solid rgba(0, 0, 0, 0.1);
    height: 30px;
    font-size: 18px;
    font-weight: 300;
    border-radius: 5px;
    padding: 10px 20px;
    transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
    line-height: 1.42857;
    color: #555555;
}

.section-input:focus {
    outline: none;
    box-shadow: none;
    border-color: #292f51
}

.error {
    border-color: #FF0000;
}

.section-error-message {
    color: #FF0000;
    padding: 5px;
}

.section-error-message-hidden {
    visibility: hidden;
    padding: 5px;
}

:-ms-input-placeholder {
    font-family: "Raleway", Arial, sans-serif;
}

::placeholder {
    font-family: "Raleway", Arial, sans-serif;
}

@media screen and (max-width: 576px) {

    .form-section-container {
        padding: 10px;
    }
}



/* The Following is for form upload */
.file-upload-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.file-upload-section {
    border-radius: 25px;
    border: 2px solid rgba(0, 0, 0, 0.1);
    font-size: 18px;
    font-weight: 300;
    border-radius: 5px;
    padding: 30px;
    line-height: 1.42857;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70vw;
}

.file-upload-title {
    font-size: 18px;
    font-weight: 300;
    width: 100%;
    text-align: start;
    padding: 10px;
}

.file-upload-text {
    padding: 0px;
}

input[type="file"] {
    opacity: 0;
    position: absolute;
    pointer-events: none;
    width: 1px;
    height: 1px;
}

input[type="file"] + label {
    color: #0000EE;
    text-decoration: underline;
    cursor: pointer;
}

@media screen and (max-width: 576px) {
    .file-upload-title {
        width: unset;
    }

    .file-upload-container {
        align-items: start;
    }

    .file-upload-section {
        width: unset;
    }
}
.file-uploaded-card-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    width: 80%;
    padding: 10px 20px;
}

.file-uploaded-card-container:hover {
    border: 2px solid rgba(0, 0, 0);
}
.job-panel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
}

.secondary-heading {
    color: #43273f;
}

.column {
    width: 50%;
}

.detail-column {
    width: 50%;
    padding: 15px;
    display: flex;
    justify-content: flex-start;
}

.details-section {
    display: flex;
    align-items: center;
}

.apply-now-button {
    width: 30%;
    display: flex;
    flex-direction: row;
}

.summary-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

@media screen and (max-width: 576px) {
    .summary-section {
        display: flex;
        flex-direction: column;
    }

    .column {
        width: unset;
    }

    .details-section {
        display: flex;
        flex-direction: column;
    }

    .detail-column {
        width: unset;
    }

    .apply-now-button {
        width: 100%;
    }
}
.contact-us-page-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex: 1.2 1;
    margin-top: 100px;
}

.contact-us-info-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.contact-us-form-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: baseline;
    padding: 20px;
}

.contact-type-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: -webkit-max-content;
    width: max-content;
}

.contact-us-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
}

.contact-content {
	float: left;
	width: 100%;
    position: relative;
    font-size: 16px;	
    content: "\e9d1";
    text-decoration: none;
    display: flex;
    font-weight: 400;
    line-height: 1.7;
    color: #828282;
}

.crestview-title {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #000000;
    font-size: 2vw;
    margin: 15px;
    font-weight: bold;
}

.contact-us-title {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #000000;
    font-size: 2vw;
    margin-left: 15px;
    margin-top: 15px;
    font-weight: bold;
}

.contact-type-section {
    display: flex;
    flex-direction: column;
    font-family: 'Roboto', sans-serif;
}

.google-map-small {
    display: none;
}

.google-map-medium {
    display: flex;
}

.google-map-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px;
}

@media screen and (max-width: 576px) {
    .contact-us-page-container {
        flex-direction: column;
        margin-top: 20px;
    }

    .contact-us-form-container {
        padding: unset;
        align-items: center;
    }

    .contact-us-title {
        display: none;
    }

    .contact-us-info-container {
        width: 100%;
        padding: unset;
    }

    .contact-us-info {
        padding: 30px 0px;
    }

    .crestview-title {
        font-size: 6vw;
    }

    .contact-content {
        font-size: 20px;
    }

    .google-map-container {
        width: inherit;
    }

    .google-map-small {
        display: flex;
        width: 100%;
    }
    
    .google-map-medium {
        display: none;
    }
}
.page-buttons-container {
	display: flex;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1200;
    opacity: 1;
    transition: 0.5s;
}

.page-buttons-container a {
    width: 50px;
    height: 40px;
    display: table;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;
    text-align: center;
    border-radius: 4px;
}

.page-buttons-container a i {
    height: 40px;
    display: table-cell;
    vertical-align: middle;
}

.page-buttons-container a:hover, .page-buttons-container a:active, .page-buttons-container a:focus {
    text-decoration: none;
    outline: none;
}
.back-to-top-button-container {
    display: flex;
    height: auto;
    justify-content: center;
    align-items: center;
    background-color: grey;
}
.sticky-header-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1200;
    box-shadow: 0px 0px 10px 10px #888888;
}

.sticky-header-alert-container {
    display: flex;
    background: rgba(171,146,110, .95);
    color: rgb(41, 47, 81);
    width: 100%;
    padding: 10px;
    font-size: 20px;
    justify-content: center;
    align-items: center;
}

.close-button-covid-alert {
    cursor: pointer;
}

.covid-alert-container {
    display: flex;
    flex-direction: row;
    background: rgba(171,146,110, .95);
}

.sticky-header-inner-container {
    display: flex;
    background: rgba(41,47,81, .9);
    color: #000000;
    width: 100%;
    justify-content: space-between;
}

/* The sticky class is added to the header with JS when it reaches its scroll position */
.sticky {
    position: fixed;
    top: 0;
    width: 100%;
}

/* Add some top padding to the page content to prevent sudden quick movement (as the header gets a new position at the top of the page (position:fixed and top:0) */
.sticky + .content {
    padding-top: 102px;
}

.cv-logo-container {
    display: flex;
    flex-direction: row;
    background: #F5F5F5;
    width: inherit;
    height: inherit;
}

.cv-logo-container-header {
    display: flex;
    width: 22%;
    height: auto;
    padding: 7px;
}

.cv-logo-link {
    width: inherit;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cv-logo-image {
    background-color: transparent;
    width: 100%;
    height: auto;
}

.nav_options-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.header-info-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.header-contact-info {
    display: flex;
}

.header-navigation {
    display: flex;
    height: inherit;
    width: auto;
    justify-content: center;
    align-items: center;
    padding-right: 10px;
}

.header-nav-link {
    font-size: 22px;
    text-align: center;
    color: #ab926e;
    padding: 5px;
    text-decoration: none;
    font-weight: bold;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.header-nav-link-icon {
    display: none;
}

.header-nav-barrier {
    font-size: 25px;
    text-align: center;
    color: #ab926e;
}

.header-nav-link-hidden {
    display: none;
}

/* Dropdown Button */
.dropbtn {
    background-color: inherit;
    color: #ab926e;
    padding: 5px;
    font-size: 22px;
    border: none;
    font-family: "Raleway", Arial, sans-serif;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
}
  
/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
}
  
/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    top: 100%;
}
  
/* Links inside the dropdown */
.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-icon {
    display: flex;
    justify-content: center;
    align-items: center;
}
  
/* Change color of dropdown links on hover */
.dropdown-content a:hover {
    background-color: #ab926e;
    color: #292f51;
}
  
/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}
  
/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {background-color: rgba( 0, 0, 0, .2);}

@media screen and (max-width: 576px) {
    .header-nav-link {
        font-size: 12px;
        white-space: nowrap;
        width: 100%;
        border-left: 1px solid grey;
        border-right: 1px solid grey;
        padding: unset;
    }

    .header-nav-link-icon {
        display: flex;
    }

    .sticky-header-container {
        top: unset;
        bottom: 0;
        background-color: #ffffff;
        border-top: 1px solid black;
        box-shadow: 0px 0px 10px 10px #888888;
        /* height: 8%; */
    }

    .header-navigation {
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        width: 100%;
        flex: 1 1;
        padding: unset;
    }

    .header-info-container {
        width: 100%;
    }

    .cv-logo-image {
        max-width: 12vw;
    }

    .dropbtn {
        font-size: 12px;
        display: flex;
        flex-direction: column;
    }

    .dropdown-icon {
        display: none;
    }

    .dropdown {
        width: 100%;
        padding: unset;
        border-left: 1px solid grey;
        border-right: 1px solid grey;
    }

    .dropdown-content {
        bottom: 100%;
        font-size: 12px;
        min-width: 120px;
        top: unset;
    }

    .cv-logo-container-header {
        display: none;
    }

    .header-nav-barrier {
        display: none;
    }

    .dropdown:hover .dropbtn {
        background-color: unset;
    }

    .sticky-header-alert-container {
        display: none;
    }
}
.tab-panel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    background-color: #F1F1F1;
    box-shadow: 0px 0px 10px 5px #888888;
}
.full-width-tabs-container {
    display: flex;
    flex-direction: column;
    margin: 100px;
}

@media screen and (max-width: 576px) {
    .full-width-tabs-container {
        margin: 30px;
    }
}
.logo-container {
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
    background: rgba(0, 0, 0, 0.4);
}

.banner-container {
    display: flex;
    width: 100%;
    height: auto;
    position: relative;
}

.picture-container {
    width: 100%;
    height: auto;
    opacity: .3;
}
.picture-and-bullets-container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.bullet-list-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 2vw;
    padding: 10px;
    flex: 1 1;
    -ms-flex: 1;
    -webkit-box-flex: 1;
}

.picture {
    padding: 20px;
    flex: 1 1;
    -ms-flex: 1;
    -webkit-box-flex: 1;
}

@media screen and (max-width: 576px) {
    .picture-and-bullets-container {
        flex-direction: column;
        padding: unset;
    }

    .bullet-list-container {
        font-size: 5vw;
        padding: unset;
    }
}
.service-section-title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #000000;
    font-size: 5vw;
    margin: 30px;
    font-weight: bold;
}

@media screen and (max-width: 576px) {
    .service-section-title-container {
        margin: 35px 10px 0px 10px;
        font-size: 9vw;
        width: unset;
    }
} 
.custom-text-container {
    display: flex;
    padding: 20px;
    margin: 20px 100px;
    font-size: 20px;
}

@media screen and (max-width: 576px) {
    .custom-text-container {
        padding: 0px;
        margin: 20px;
        font-size: 18px;
    }
}
.job-panel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
}

.secondary-heading {
    color: #43273f;
}

.column {
    width: 100%;
}

.details-section-text {
    display: flex;
    flex-direction: column;
}

.detail-column {
    width: 50%;
    padding: 15px;
    display: flex;
    justify-content: flex-start;
}

.details-section {
    display: flex;
    align-items: center;
    font-size: 20px;
}

.apply-now-button {
    width: 30%;
    display: flex;
    flex-direction: row;
}

.summary-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

@media screen and (max-width: 576px) {
    .summary-section {
        display: flex;
        flex-direction: column;
    }

    .column {
        width: unset;
    }

    .details-section {
        display: flex;
        flex-direction: column;
    }

    .detail-column {
        width: unset;
    }

    .apply-now-button {
        width: 100%;
    }
}

body {
  margin: 0;
  padding: 0;
  font-family: "Raleway", Arial, sans-serif;
}

