@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@700&display=swap');

.footer-container {
    background-color: #292f51;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 30px 50px 30px;
    box-shadow: 0px 0px 20px 0px #888888;
    font-family: 'Roboto', sans-serif;
}

.footer-text {
    color: #ab926e;
    font-size: 25px;
    padding: 5px;
    text-decoration: none;
}

.footer-text-barrier {
    color: #ab926e;
    font-size: 25px;
    padding: 5px;
}

.connect-with-us-text {
    color: #ab926e;
    font-size: 25px;
    padding: 20px;
}

.social-media-container {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.facebook-icon {
    color: #ab926e;
}

.facebook-icon:hover {
    color: #4267B2;
}

.instagram-icon {
    color: #ab926e;
}

.instagram-icon:hover {
    color: #DD2A7B;
}

.linkedin-icon {
    color: #ab926e;
}

.linkedin-icon:hover {
    color: #0073b1;
}

.eqo-handicap-container {
    display: flex;
    -ms-flex-direction: row;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
}

.eqo-handicap-picture {
    width: 5%;
}

.footer-alt-info-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.footer-link {
    text-decoration: none;
    color: #ab926e;
    padding: 10px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer-logo-container {
    width: 10%;
    height: auto;
    padding: 15px;
}


.footer-link-section-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: start;
    width: 100%;
    margin: 0px 20px 20px 20px;
}

.footer-link-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 50%;
    color: #ab926e !important;
    font-size: 20px;
    padding: 20px;
    text-decoration: none;
}

.footer-divider {
    width: inherit;
    display: flex;
    height: 3px;
    background-color: #292f51;
    border-radius: 5px;
}

.footer-section-link {
    margin: 10px;
    cursor: pointer;
}

.footer-awards-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.best-meals-and-dining-award-container {
    width: 9%;
}

.best-in-senior-living-award-container {
    width: 15%;
    cursor: pointer;
}

@media screen and (max-width: 576px) {
    .footer-container {
        padding: 0px 20px 60px 20px;
    }

    .footer-divider {
        margin: 5px;
        width: 100%;
    }

    .footer-title {
        font-size: 26px;
        text-align: center;
    }

    .footer-text {
        font-size: 20px;
        margin-bottom: 0px;
    }

    .footer-text-barrier {
        display: none;
    }

    .footer-link-section {
        margin: 0px;
    }

    .footer-logo-container { 
        width: 30%;
        padding: 20px;
    }

    .footer-alt-info-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        white-space: nowrap;
        text-decoration: none;
    }

    .eqo-handicap-picture {
        width: 15%;
    }

    .best-meals-and-dining-award-container {
        width: 24%;
    }
    
    .best-in-senior-living-award-container {
        width: 40%;
    }
}