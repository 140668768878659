.outlined-button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-content: center;
    width: -webkit-fill-available;
}

.btn {
    margin-right: 4px;
    margin-bottom: 4px;
    font-family: "Raleway", Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    border-radius: 4px;
    -webkit-transition: 0.5s;
    -o-transition: 0.5s;
    transition: 0.5s;
    padding: 8px 30px;
    text-decoration: none;
}

.btn.btn-md {
    padding: 8px 20px !important;
}

.btn.btn-lg {
    padding: 18px 36px !important;
}

.btn:hover, .btn:active, .btn:focus {
    box-shadow: none !important;
    outline: none !important;
}

.btn-primary {
    background: #292f51;
    color: #ffffff;
    border: 2px solid #292f51;
}

.btn-primary:disabled:hover {
    background: #cccccc;
}

.btn-primary:disabled {
    background: #cccccc;
    border: none;
}

.btn-primary:enabled:hover, .btn-primary:focus, .btn-primary:active {
    background: #292f51 !important;
    border-color: #43273f !important;
    cursor: pointer;
}

.btn-primary.btn-outline {
    display: flex;
    justify-content: center;
    width: auto;
    align-items: center;
    text-align: center;
    background: rgba(256,256,256,0.8);
    color: #292f51;
    border: 2px solid #43273f;
    width: 100%;
}

.btn-primary.btn-outline:hover, .btn-primary.btn-outline:focus, .btn-primary.btn-outline:active {
    background: #292f51;
    color: #fff;
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .btn-primary.btn-outline {
        color: white;
        background: rgba(121, 92, 50, 0.9);
    }
}

@media screen and (max-width: 576px) {
    .outlined-button {
        margin: 0px 20px;
    }
}