.video-header-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.video-player-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;
}

.react-player {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

