.iframe {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0px;
    zoom: 3;
}

.iframe-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 576px) {

    .iframe {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px 0px;
        zoom: 2;
    }
    
    .iframe-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

}