.file-uploaded-card-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 2px solid rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    width: 80%;
    padding: 10px 20px;
}

.file-uploaded-card-container:hover {
    border: 2px solid rgba(0, 0, 0);
}