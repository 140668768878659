.section-title-container {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #000000;
    font-size: 3vw;
    margin: 20px;
    margin-top: 40px;
    font-weight: bold;
}

@media screen and (max-width: 576px) {
    .section-title-container {
        margin: 35px 10px 0px 10px;
        font-size: 9vw;
        width: unset;
    }
}