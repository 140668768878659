.coming-soon-banner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: inherit;
    margin: 40px 0px 70px 0px;
    background-color: #ab926e;
    box-shadow: 0px 0px 5px 0px #888888;
}

.coming-soon-button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    margin: 20px;
}

.coming-soon-header {
    margin: 10px;
    font-size: 50px;
    font-weight: bold;
    text-align: center;
    color: #292f51;
}

.coming-soon-subheader {
    margin: 20px 100px;
    font-size: 25px;
    font-weight: bold;
    text-align: center;
    color: #292f51;
}

@media screen and (max-width: 576px) {
    .coming-soon-header {
        font-size: 35px;
    }

    .coming-soon-subheader {
        font-size: 20px;
    }

    .coming-soon-button-container {
        width: unset;
    }
}