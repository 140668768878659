.sticky-header-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1200;
    box-shadow: 0px 0px 10px 10px #888888;
}

.sticky-header-alert-container {
    display: flex;
    background: rgba(171,146,110, .95);
    color: rgb(41, 47, 81);
    width: 100%;
    padding: 10px;
    font-size: 20px;
    justify-content: center;
    align-items: center;
}

.close-button-covid-alert {
    cursor: pointer;
}

.covid-alert-container {
    display: flex;
    flex-direction: row;
    background: rgba(171,146,110, .95);
}

.sticky-header-inner-container {
    display: flex;
    background: rgba(41,47,81, .9);
    color: #000000;
    width: 100%;
    justify-content: space-between;
}

/* The sticky class is added to the header with JS when it reaches its scroll position */
.sticky {
    position: fixed;
    top: 0;
    width: 100%;
}

/* Add some top padding to the page content to prevent sudden quick movement (as the header gets a new position at the top of the page (position:fixed and top:0) */
.sticky + .content {
    padding-top: 102px;
}

.cv-logo-container {
    display: flex;
    flex-direction: row;
    background: #F5F5F5;
    width: inherit;
    height: inherit;
}

.cv-logo-container-header {
    display: flex;
    width: 22%;
    height: auto;
    padding: 7px;
}

.cv-logo-link {
    width: inherit;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cv-logo-image {
    background-color: transparent;
    width: 100%;
    height: auto;
}

.nav_options-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
}

.header-info-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.header-contact-info {
    display: flex;
}

.header-navigation {
    display: flex;
    height: inherit;
    width: auto;
    justify-content: center;
    align-items: center;
    padding-right: 10px;
}

.header-nav-link {
    font-size: 22px;
    text-align: center;
    color: #ab926e;
    padding: 5px;
    text-decoration: none;
    font-weight: bold;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.header-nav-link-icon {
    display: none;
}

.header-nav-barrier {
    font-size: 25px;
    text-align: center;
    color: #ab926e;
}

.header-nav-link-hidden {
    display: none;
}

/* Dropdown Button */
.dropbtn {
    background-color: inherit;
    color: #ab926e;
    padding: 5px;
    font-size: 22px;
    border: none;
    font-family: "Raleway", Arial, sans-serif;
    font-weight: bold;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
}
  
/* The container <div> - needed to position the dropdown content */
.dropdown {
    position: relative;
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
}
  
/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    top: 100%;
}
  
/* Links inside the dropdown */
.dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
}

.dropdown-icon {
    display: flex;
    justify-content: center;
    align-items: center;
}
  
/* Change color of dropdown links on hover */
.dropdown-content a:hover {
    background-color: #ab926e;
    color: #292f51;
}
  
/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}
  
/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {background-color: rgba( 0, 0, 0, .2);}

@media screen and (max-width: 576px) {
    .header-nav-link {
        font-size: 12px;
        white-space: nowrap;
        width: 100%;
        border-left: 1px solid grey;
        border-right: 1px solid grey;
        padding: unset;
    }

    .header-nav-link-icon {
        display: flex;
    }

    .sticky-header-container {
        top: unset;
        bottom: 0;
        background-color: #ffffff;
        border-top: 1px solid black;
        box-shadow: 0px 0px 10px 10px #888888;
        /* height: 8%; */
    }

    .header-navigation {
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        width: 100%;
        flex: 1;
        padding: unset;
    }

    .header-info-container {
        width: 100%;
    }

    .cv-logo-image {
        max-width: 12vw;
    }

    .dropbtn {
        font-size: 12px;
        display: flex;
        flex-direction: column;
    }

    .dropdown-icon {
        display: none;
    }

    .dropdown {
        width: 100%;
        padding: unset;
        border-left: 1px solid grey;
        border-right: 1px solid grey;
    }

    .dropdown-content {
        bottom: 100%;
        font-size: 12px;
        min-width: 120px;
        top: unset;
    }

    .cv-logo-container-header {
        display: none;
    }

    .header-nav-barrier {
        display: none;
    }

    .dropdown:hover .dropbtn {
        background-color: unset;
    }

    .sticky-header-alert-container {
        display: none;
    }
}