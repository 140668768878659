.custom-text-container {
    display: flex;
    padding: 20px;
    margin: 20px 100px;
    font-size: 20px;
}

@media screen and (max-width: 576px) {
    .custom-text-container {
        padding: 0px;
        margin: 20px;
        font-size: 18px;
    }
}