.careers-page-form-container {
    width: -webkit-fill-available;
    padding: 20px;
}

.form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: -webkit-fill-available;
    padding: 20px;
}

.form-row-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    padding: 5px;
    width: 100%;
}

.form-submit-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: inherit;
}

.form-file-upload-container {
    width: -webkit-fill-available;
    padding: 0px 25px 25px 25px;
    display: flex;
    justify-content: center;
}

.no-job-postings {
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 30px;
    margin: unset;
}

@media screen and (max-width: 576px) {

    .form-file-upload-container {
        padding: 0px 0px 30px 0px;
    }

    .careers-page-form-container {
        padding: unset;
    }

    .form-container {
        padding: unset;
        width: unset;
    }

    .form-row-container {
        flex-direction: column;
        padding: unset;
    }

    .form-submit-row {
        flex-direction: column-reverse;
    }
}