.img-container {
    width: 100%;
    height: auto;
    position: relative;
    border-radius: 10px;
}

.webp-picture-container {
    position: relative;
    display: flex;
    justify-content: center;
	width: 100%;
	align-items: center;
}

.best-meals-and-dining-container {
    position: relative;
    display: flex;
    justify-content: center;
	align-items: center;
	padding: 30px;
}

.best-meals-and-dining {
	width: auto;
}

.expanded-webp-picture-container {
	position: relative;
    display: flex;
	justify-content: center;
	width: 45%;
}

.zoom-in-icon {
	font-size: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
}



.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .3s ease;
    background-color: rgb(165, 165, 165, .5);
}

  /* The icon inside the overlay is positioned in the middle vertically and horizontally */
.icon {
    color: white;
    font-size: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.modal-container {
	position: absolute;
	top: 50%;
	left: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 2000;
	background: rgba(256, 256, 256, .8)
}

.close-button-icon {
	position: absolute;
	right: 20px;
	top: 20px;
	cursor: pointer;
	color: #555555;
}

.close-button-icon:hover {
	color: #43273f;
}

.print-icon {
	position: absolute;
	right: 80px;
	top: 20px;
	cursor: pointer;
	color: #555555;
}

.print-icon:hover {
	color: #43273f;
}

.share-icon {
	position: absolute;
	right: 140px;
	top: 20px;
	cursor: pointer;
	color: #555555;
}

.share-icon:hover {
	color: #43273f;
}
  
/* When you move the mouse over the icon, change color */
.fa-user:hover {
	color: #43273f;
}

@media screen and (max-width: 576px) {
  .img-container {
		height: intrinsic;
	}
	
	.webp-picture-container {
		height: inherit;
	}

	.expanded-webp-picture-container {
		position: relative;
		display: flex;
		justify-content: center;
		width: 75%;
	}
}

