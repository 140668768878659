.logo-container {
    display: flex;
    width: 100%;
    height: 100%;
    position: absolute;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
    background: rgba(0, 0, 0, 0.4);
}

.banner-container {
    display: flex;
    width: 100%;
    height: auto;
    position: relative;
}

.picture-container {
    width: 100%;
    height: auto;
    opacity: .3;
}